// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-text-page-jsx": () => import("/home/clayton/projects/bluebase-website/src/templates/TextPage.jsx" /* webpackChunkName: "component---src-templates-text-page-jsx" */),
  "component---src-templates-narrow-text-page-jsx": () => import("/home/clayton/projects/bluebase-website/src/templates/NarrowTextPage.jsx" /* webpackChunkName: "component---src-templates-narrow-text-page-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("/home/clayton/projects/bluebase-website/src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-contact-thank-you-jsx": () => import("/home/clayton/projects/bluebase-website/src/pages/contact/thank-you.jsx" /* webpackChunkName: "component---src-pages-contact-thank-you-jsx" */),
  "component---src-pages-index-jsx": () => import("/home/clayton/projects/bluebase-website/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/clayton/projects/bluebase-website/.cache/data.json")

